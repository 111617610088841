<template>
	<div>
		<main-nav :activeIndex="activeIndex"></main-nav>
		<div class="solution-header-container">
			<div class="solution-header-text">
				<h1 data-caption-delay="0" class="wow fadeInLeft">适配以太网测试的嵌入式软件开发服务</h1>
				<p data-caption-delay="100" class="wow fadeInRight">适配以太网TC8测试的嵌入式软件方案</p>
				<div data-caption-delay="200" class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
					<div>立即咨询</div>
				</div>
			</div>
		</div>
		<section class="solution-intros ecusoft-solution-intro wow fadeInLeft">
			<div class="container">
				<div class="normal-title">解决方案介绍</div>
				<div class="all-intro">
					<div class="intro-text">
						<p>伴随着以太网在汽车行业的大量应用，为了帮助汽车厂商客观地评估以太网通信软件的可靠性，配合OPEN Alliance发布的OPEN
							TC8车载以太网一致性测试规范，东信创智开发了UpperTester及SOME/IP ETS软件模块。</p>
					</div>
					<div class="intro-text">
						<h3>测试规范</h3>
						<ul>
							<li>OPEN Alliance Automotive Ethernet ECU Test Specification Layer 3-7 Version 3.0</li>
							<li>Specification of Testability Protocol and Service Primitives AUTOSAR TC Release 1.2.0
							</li>
						</ul>
						<ol>
							<li>UpperTester，配合以太网TCP/IP协议一致性测试，适配平台：
								<ul>
									<li>Linux</li>
									<li>QNX</li>
									<li>Android</li>
									<li>AUTOSAR CP</li>
								</ul>
							</li>
							<li>SOME/IP ETS，配合SOME/IP一致性测试，适配平台：
								<ul>
									<li>vsomeip</li>
									<li>AUTOSAR CP</li>
								</ul>
							</li>
						</ol>
					</div>
				</div>
			</div>
		</section>

		<bzInfo style="margin-top: 0" />
		<main-footer></main-footer>
	</div>
</template>

<script>
	import mainNav from '@/components/MainNav'
	import mainFooter from '@/components/MainFooter'
	import bzInfo from '@/components/BzInfo'

	export default {
		name: "ecuSoft",
		components: {
			mainNav,
			mainFooter,
			bzInfo
		},
		data() {
			return {
				tabFixed: false,
				activeIndex: '2-2',
				activeProduct: '1',
				functionList: [{
					content: '可帮助客户实现Demo级的基础软件集成服务，实现MCAL、通信、诊断、网络管理、OS、RTE、SWC、CDD等模块及功能的目标板快速适配，客户可在此基础上进行功能开发，缩短开发周期'
				}, {
					content: '也可帮助客户实现整车开发各阶段的AUTOASR基础软件配置、开发与集成等需求，满足测试要求，达到量产标准'
				}],
				chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
			}
		},
		mounted() {
			new this.$wow.WOW().init();
			document.title = "适配以太网测试的嵌入式软件开发服务 - 解决方案 - 东信创智 ";
			window.addEventListener("scroll", this.handleScroll);
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll);
		},
		methods: {
			linkUrl(url) {
				window.open(url, '_blank') // 在新窗口打开外链接
				// window.location.href =this.indexro;  //在本页面打开外部链接
			},
			handleScroll() {
				if (document.documentElement.clientWidth > 1100) {
					let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
					if (top < 0 && top > -630) {
						this.tabFixed = !this.tabFixed;
					} else if (document.documentElement.scrollTop > 630) {
						this.tabFixed = true;
					}
				} else {
					let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
					if (top < 0 && top > -250) {
						this.tabFixed = !this.tabFixed;
					} else if (document.documentElement.scrollTop > 250) {
						this.tabFixed = true;
					}
				}
			},
			getTabFixedClass() {
				if (document.documentElement.clientWidth < 1100) {
					return 'sw-tab-title-mobile-fixed'
				} else {
					return 'sw-tab-title-fixed'
				}
			},
			handleTabClick() {
				if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
					document.documentElement.scrollTop = 630
				} else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
					document.documentElement.scrollTop = 250
				}
			}
		}
	}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./controllerSoftwareEmbedded.vue?vue&type=template&id=2eb0b456&scoped=true"
import script from "./controllerSoftwareEmbedded.vue?vue&type=script&lang=js"
export * from "./controllerSoftwareEmbedded.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eb0b456",
  null
  
)

export default component.exports